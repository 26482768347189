<template>
  <div>
     <div class="base-timer">
        <svg
          class="base-timer__svg"
          viewBox="0 0 100 100"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g class="base-timer__circle">
            <circle
              class="base-timer__path-elapsed"
              cx="50"
              cy="50"
              r="46.5"
            />
          </g>
        </svg>
        <span class="base-timer__label">
          {{ formattedTimeLeft }}
        </span>
      </div>
      
    <CCard>
      <CForm @submit.prevent="submit" ref="form">
        <CCardHeader>
          <h1>{{ quiz.title }} </h1>
        </CCardHeader>
        <CCardBody>
          <div class="row" v-for="(question, index) in quiz.questions" :key="index">
            <div class="col-md-12">
              <h3>{{ question.question }}</h3>
              <div v-for="(option, index) in question.options" :key="index">
                <input type="radio" v-model="question.answer" v-bind:value="option.id">
                <label class="ml-2">{{ option.option }}</label>
                <br>
              </div>
            </div>
          </div>
        </CCardBody>
        <CCardFooter>
          <CButton color="success" type="submit">
            Finalizar
          </CButton>
        </CCardFooter>
      </CForm>
    </CCard>
  </div>
</template>

<script>
import axios from 'axios'
import store from '@/store'
export default {
  name: 'Quiz',
  props: [
    'id'
  ],
  data: function () {
    return {
        course: {},
        quiz: {},
        show: true,
        timeLimit: 20,
        timePassed: 0,
        isCollapsed: true,
        formattedTimeLeft: '00:00',
        loremIpsum: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.'
    }
  },
  mounted() {
    const token = store.state.user.token;
      axios
        .get(process.env.VUE_APP_BACKEND_URL+'/api/course/' + this.$route.params.id + '/quiz', {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(response => {
          this.quiz = response.data.data;
          this.quiz.max_time = this.quiz.max_time * 60;
          this.countDownTimer()
        })
  },
  methods: {
    submit() {
      const token = store.state.user.token;
      axios
        .post(process.env.VUE_APP_BACKEND_URL+'/api/quiz/' + this.quiz.id + '/attempt', this.quiz, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(response => {
          this.$router.push('/course/' + response.data.attempt.id + '/quiz/results')
        })
    },
    countDownTimer() {
        if(this.quiz.max_time > 0) {
            setTimeout(() => {
                this.quiz.max_time -= 1
                this.countDownTimer()
            }, 1000)
        }
        const minutes = Math.floor(this.quiz.max_time / 60)
        let seconds = this.quiz.max_time % 60

        if (seconds < 10) {
          seconds = `0${seconds}`
        }

        this.formattedTimeLeft = `${minutes}:${seconds}`

        if (this.quiz.max_time == 0) {
          this.submit()
        }
    }
  }
}
</script>

<style scoped lang="scss">
/* Sets the containers height and width */
.base-timer {
  position: fixed;
  right: 0;
  z-index: 1000;
  width: 100px;
  height: 100px;
  margin-right: 50px;
/* Removes SVG styling that would hide the time label */
  &__circle {
    fill: none;
    stroke: none;
  }
/* The SVG path that displays the timer's progress */
  &__path-elapsed {
    stroke-width: 7px;
    stroke:grey;
  }

  &__label {
    position: absolute;    
    
    /* Size should match the parent container */    
    width: 100px;
    height: 100px;
    /* Keep the label aligned to the top */    
    top: 0;
    /* Create a flexible box that centers content vertically and horizontally */    
    display: flex;
    align-items: center;
    justify-content: center;
    /* Sort of an arbitrary number; adjust to your liking */
    font-size: 20px;
  }
}
</style>